import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class YourDogsHealth extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(3);
    }

    render(){
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Keeping your dog fit and in good general health is an important part of keeping their heart healthy. But, how can you tell if your dog is healthy?</p>
                                <p className="SectionText">Here's what to look for &ndash; bright eyes, no discharge from the ears, eyes or nose, a healthy coat free of parasites and a healthy appetite. And depending on the age of your dog, active energy and movement with no signs of pain.</p>
                                <p className="SectionText">Of course, just as a person needs to have good habits to stay in shape, so does your dog. Your vet can give you advice about good habits for your dog and help you put together a health programme.</p>
                                <p className="SectionText">Here's some general recommendations about diet, exercise, and routine check-ups to help you keep your dog in shape.</p>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(YourDogsHealth);
